import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
//
import cameroonVillage from "./images/Cameroon Village.jpg";
import cameroonWomen from "./images/Cameroon Women.jpg";
import whiteMountains from "./images/White Mountains.jpg";
import waikiki from "./images/Waikiki.jpg";
import ussMakinIsland from "./images/USS Makin Island.jpg";
import outsideFlag from "./images/Outside Flag.jpg";
import sedona from "./images/Sedona.jpg";
import leavingPearl from "./images/Leaving Pearl.jpg";
import hanaumaBay from "./images/Hanauma Bay.jpg";
import grandCanyon from "./images/Grand Canyon.jpg";
//

const shuffle = (array: number[]) => {
    let currentIndex = array.length;
    let randomIndex: number;
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }
    return array;
}

type DataItem = {
    image: any,
    text: string
};

let imageArray = [] as DataItem[];
imageArray.push({ image: cameroonVillage, text: "Cameroon West Africa" });
imageArray.push({ image: whiteMountains, text: "White Mountains" });
imageArray.push({ image: waikiki, text: "Waikiki" });
imageArray.push({ image: ussMakinIsland, text: "USS Makin Island" });
imageArray.push({ image: outsideFlag, text: "Outside Flag" });
imageArray.push({ image: hanaumaBay, text: "Hanauma Bay" });
imageArray.push({ image: sedona, text: "Sedona" });
imageArray.push({ image: leavingPearl, text: "Leaving Pearl" });
imageArray.push({ image: grandCanyon, text: "Grand Canyon" });
imageArray.push({ image: cameroonWomen, text: "Cameroonian Women" });

let orderArray = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
//
//            className="d-block w-100"

const carouselItems = orderArray.map(i => {
    return <Carousel.Item key={i}>
        <img
            className="d-block w-100"
            src={imageArray[i].image}
            alt={imageArray[i].text}
            title={imageArray[i].text}
        />
    </Carousel.Item>;
})

const MyCarousel = () => {
    //
    //    
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex: number, e: any) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect} interval={9999999999}>
            {carouselItems}
        </Carousel>
    );
}

export default MyCarousel;